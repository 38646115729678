export const environment = {
  production: false,
  MetaNameDescription: 'Split Timing consists in measuring the times in 7 separate sections, being straights and turns. The competitor will know his time on each stretch of the course and on each turnaround, will have several evaluation options by sectors, and will be able to compare it to the other animals.',
  MetaNameOGDescription: 'Split Timing consists in measuring the times in 7 separate splits, being straights and flips.',
  apiFlespi:'https://flespi.io/storage/containers/',
  flespiToken:'SEsJ98MftFqrkMVhQXbu2wP5iCsdbaYRCeqCc3FwwVLExwJchLw7792fBjgBj5iM',
  urlFront : "www.splittiming.com",
  urlLambda : "https://jvalz6oba5.execute-api.sa-east-1.amazonaws.com/Prod/SplittimingAPI",
  language : 'en',
  format_date :  "MM/DD/YYYY",
  logoHeaderFile : "split-timing-fundotransparente-branco.png",
  logoHomeHorseComp:"comp.png",
  logoBlack:"split-timing-fundotransparente2.png",
  logoTransmissao:"pocoto.jpg",
  whatIsVideoUrl : "https://www.youtube.com/embed/L9GoXHF66G0",
  howToUseVideoUrl : "https://www.youtube.com/embed/7ekh150Id5s",
  months: "Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
  fullMonths:"January,February,March,April,May,June,July,August,September,October,November,December",
  by_first_analysis_value:4.99,
  by_second_analysis_value:8.99,
  by_third_analysis_value:11.99,
  // link_payment_1:'https://buy.stripe.com/3csg1B5sq71XeZ26or',
  // link_payment_2:'https://buy.stripe.com/14kbLl3ki5XTbMQ3cg',
  // link_payment_3:'https://buy.stripe.com/9AQeXxdYWcmhaIM5kp',
  link_payment_1:'https://buy.stripe.com/test_6oE4gD3NG2trcg0dQV',
  link_payment_2:'https://buy.stripe.com/test_3cs14r83W7NL7ZK7sw',
  link_payment_3:'https://buy.stripe.com/test_7sI4gD0Bu9VT4NycMP',
  link_stripe_special_report_by_event: 'https://buy.stripe.com/dR616H4omdql9EIdR6',
  link_stripe_special_report_by_class: 'https://buy.stripe.com/7sI7v5bQOeup4koaET',
  value_stripe_special_report_by_event: 29.99,
  value_stripe_special_report_by_class: 19.99,
  link_stripe_full_report_by_event: 'https://buy.stripe.com/7sIeXx9IGcmh9EIcN6',
  link_stripe_full_report_by_class: 'https://buy.stripe.com/cN24iT6wugCx18c5kD',
  value_stripe_full_report_by_event: 49.99,
  value_stripe_full_report_by_class: 39.99,
};
